.layout-main-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;
    padding: 7rem 2rem 2rem 4rem;
    transition: margin-left $transitionDuration;
}

.layout-main {
    flex: 1 1 auto;
}

.user-DP-div {
    position: relative;
    margin-bottom: 25px;
    width: 100%;
}
.user-DP-div .edit {
    position: absolute;
    bottom: -20px;
    right: 37px;
    cursor: pointer;
}
.user-DP-div .delete {
    position: absolute;
    bottom: -20px;
    right: 8px;
    color: #D70040;
    cursor: pointer;
}

img.weaponImageTag {
    width: 120px;
}

.weapon-image-wrapper {
    display: flex;
    flex-direction: column;
}

label.weaponImageLabel {
    position: relative;
    padding: 10px 15px;
    border: 1px solid #22C55E;
    /* display: inline-block; */
    width: max-content;
    background-color: #22C55E;
    color: white;
    margin-top: 30px;
    cursor: pointer;
}

.weapon-image-div {
    position: relative;
    width: max-content;
}

.weapon-edit-div {
    position: absolute;
    bottom: 0;
    right: 0;
}

.weapon-edit-div i {
    color: #fe5100;
    cursor: pointer;
}

.cancel-question {
    position: absolute;
    top: 15px;
    right: 0;
}
.question-div-wrapper {
    position: relative;
    padding-right: 2rem;
}
