* {
    box-sizing: border-box;
}

html {
    height: 100%;
    font-size: $fontSize;
}

body {
    font-family: var(--font-family);
    color: var(--text-color);
    background-color: var(--surface-ground);
    margin: 0;
    padding: 0;
    min-height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a, button {
    text-decoration: none;
    color: var(--primary-color);
}

.layout-theme-light {
    background-color: #edf1f5;
}
.p-button-detail {
    background: linear-gradient(180deg, #3b82f6 0%, #3b82f6 100%);
    color: #ffffff;
    border: 1px solid #3b82f6;
}
.p-button-detail:hover {
    background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%) !important;
    color: #3b82f6 !important;
    border: 1px solid #3b82f6 !important;
}


