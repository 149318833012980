.overlay-loading {position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0,0,0,0.4);
    z-index: 99;
    display: grid;
    place-items: center;
}
.cancel-button{
    background: transparent;
    color: #fe5100;
}
.customBackground{
    background-image: url('../../images/background.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
    overflow: hidden;
}